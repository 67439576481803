import React from "react"
import { Link } from "gatsby"
import { ChevronRight } from "react-feather"
import logo from "../images/logo.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="home-container">
      <article>
        <p>
          <span>"We" or "us" or the "Company" Aide</span>
          <span>
            {" "}
            value our visitors&rsquo; privacy. We've summarized what information
            we might collect from a registered user or other visitor, "you", and
            what we will and will not do with it in this privacy policy
            effective August 1st, 2020.
          </span>
        </p>
        <p>
          <span>
            Please note that this privacy policy does not govern the collection
            and use of information by companies that Aide does not control, nor
            by individuals not employed or managed by Aide. If you visit a Web
            site that we mention or link to, be sure to review its privacy
            policy before providing the site with information.
          </span>
        </p>
        <h2>What we do with your personally identifiable information</h2>
        <p>
          <span>
            It is always up to you whether to disclose personally identifiable
            information to us, although if you elect not to do so, we reserve
            the right not to register you as a user or provide you with any
            products or services. &ldquo;
          </span>
          <span>Personally identifiable information</span>
          <span>
            &rdquo; means information that can be used to identify you as an
            individual, such as, for example:
          </span>
        </p>
        <ul>
          <li>
            <span>
              your name, company, email address, phone number, billing address,
              and shipping address
            </span>
          </li>
          <li>
            <span>your Aide user ID and password</span>
          </li>
          <li>
            <span>credit card information</span>
          </li>
          <li>
            <span>any account-preference information you provide us</span>
          </li>
          <li>
            <span>
              your computer&rsquo;s domain name and IP address, indicating
            </span>
            <span>
              <br />
            </span>
            <span>where your computer is located on the Internet</span>
          </li>
          <li>
            <span>
              session data for your login session, so that our computer can
              &lsquo;talk&rsquo; to yours while you are logged in
            </span>
          </li>
        </ul>
        <p>
          <span>
            If you do provide personally identifiable information to us, either
            directly or through a reseller or other business partner, we will:
          </span>
        </p>
        <ul>
          <li>
            <span>
              not sell or rent it to a third party without your permission
              &mdash; although unless you opt out (see below), we may use your
              contact information to provide you with information we believe you
              need to know or may find useful, such as (for example) news about
              our services and products and modifications to the Terms of
              Service;
            </span>
          </li>
          <li>
            <span>
              take commercially reasonable precautions to protect the
              information from loss, misuse and unauthorized access, disclosure,
              alteration and destruction;
            </span>
          </li>
          <li>
            <span>not use or disclose the information except:</span>
          </li>
          <ul>
            <li>
              <span>
                as necessary to provide services or products you have ordered,
                such as (for example) by providing it to a carrier to deliver
                products you have ordered;
              </span>
            </li>
            <li>
              <span>
                in other ways described in this privacy policy or to which you
                have otherwise consented;
              </span>
            </li>
            <li>
              <span>
                in the aggregate with other information in such a way so that
                your identity cannot reasonably be determined (for example,
                statistical compilations);
              </span>
            </li>
            <li>
              <span>
                as required by law, for example, in response to a subpoena or
                search warrant;
              </span>
            </li>
            <li>
              <span>
                to outside auditors who have agreed to keep the information
                confidential;
              </span>
            </li>
            <li>
              <span>as necessary to enforce the Terms of Service;</span>
            </li>
            <li>
              <span>
                as necessary to protect the rights, safety, or property of Aide,
                its users, or others; this may include (for example) exchanging
                information with other organizations for fraud protection and/or
                risk reduction.
              </span>
            </li>
          </ul>
        </ul>
        <h2>Other information we collect</h2>
        <p>
          <span>
            We may collect other information that cannot be readily used to
            identify you, such as (for example) the domain name and IP address
            of your computer. We may use this information, individually or in
            the aggregate, for technical administration of our website; research
            and development; customer- and account administration; and to help
            us focus our marketing efforts more precisely.
          </span>
        </p>
        <h2>Cookies </h2>
        <p>
          <span>
            Aide uses &ldquo;cookies&rdquo; to store personal data on your
            computer. We may also link information stored on your computer in
            cookies with personal data about specific individuals stored on our
            servers. If you set up your Web browser (for example, Chrome or
            Firefox) so that cookies are not allowed, you might not be able to
            use some or all of the features of our website.
          </span>
        </p>
        <h2>External data storage sites </h2>
        <p>
          <span>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </span>
        </p>
        <h2>Your privacy responsibilities </h2>
        <p>
          <span>To help protect your privacy, be sure:</span>
        </p>
        <ul>
          <li>
            <span>not to share your user ID or password with anyone else;</span>
          </li>
          <li>
            <span>to log off the Aide website when you are finished;</span>
          </li>
          <li>
            <span>
              to take customary precautions to guard against
              &ldquo;malware&rdquo; (viruses, trojan horses, bots, etc.), for
              example by installing and updating suitable anti-virus software.
            </span>
          </li>
        </ul>
        <h2>Notice to European Union users</h2>
        <p>
          <span>
            Aide&lsquo;s operations are located primarily in the United States.
            If you provide information to us, the information will be
            transferred out of the European Union (EU) to the United States. By
            providing personal information to us, you are consenting to its
            storage and use as described herein.
          </span>
        </p>
        <h2>Information collected from children </h2>
        <p>
          <span>
            You must be at least 13 years old to use Aide&lsquo;s website and
            services. Aide does not knowingly collect information from children
            under 13. (See the [U.S.]&nbsp;Children&rsquo;s Online Privacy
            Protection Act.)
          </span>
        </p>
        <h2>Changes to this privacy policy </h2>
        <p>
          <span>
            We reserve the right to change this privacy policy as we deem
            necessary or appropriate because of legal compliance requirements or
            changes in our business practices. If you have provided us with an
            email address, we will endeavor to notify you by email of any
            material change to how we will use personally identifiable
            information.
          </span>
        </p>
        <h2>Questions or comments? </h2>
        <p>
          <span>
            If you have questions or comments about Aide's privacy policy, send
            an email to <a href="mailto:help@aide.app">help@aide.app</a>
          </span>
        </p>
      </article>
    </div>
  </Layout>
)

export default SecondPage
